<script lang="ts">
    import type { Color } from './Swatch.svelte';

    export let color: Color | undefined;

    // The text color with the best contrast
    let textColor: 'white' | 'black' = 'white';

    $: {
        if (!color) break $;

        // Parse the color as a hex number
        const hex = parseInt(color.hex.substring(1), 16);
        // Decode the red, green, and blue channels
        const r = (hex & 0xff0000) >> 16,
              g = (hex  & 0x00ff00) >> 8,
              b = (hex & 0x0000ff);

        const cmin = Math.min(r, g, b);
        const cmax = Math.max(r, g, b);
        
        // If the sum of cmin and cmax (lightness * 2) is over 255, black has better contrast.
        textColor = cmax + cmin > 255 ? 'black' : 'white';
    }

    function getColorId(color: Color): string {
        if (color.idOverride) {
            return color.idOverride;
        }

        if (color.shade === 'NEUTRAL') {
            return color.group;
        }

        return `${color.group}_${color.shade}`;
    }
</script>

<section>
    {#if color}
        <h1 style={`--color: ${color.hex}`}>
            <code class="preview" style={`color: ${textColor}`}>{getColorId(color)}</code> ({color.hex})
        </h1>
    {:else}
        <h1>Click a color to inspect it.</h1>
    {/if}
</section>

<style>
    section {
        margin: auto;
        flex-basis: 10%;
    }

    .preview {
        background-color: var(--color);
        padding: 3px;
        border-radius: 3px;
    }
</style>