<script lang="ts">
	import { SwatchGrid, ColorReadout } from './components';
	import type { SwatchSelectEvent, Color } from './components/Swatch.svelte';

	let activeColor: Color = undefined;

	function updateColor({ detail: event }: CustomEvent<SwatchSelectEvent>) {
		activeColor = event.color;
	}
</script>

<main>
	<ColorReadout bind:color={activeColor} />
	<SwatchGrid on:click={updateColor} />
</main>

<style>
	main {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
</style>