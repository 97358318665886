<script lang="ts" context="module">
    export interface SwatchSelectEvent {
        color: Color
    }

    export type ColorGroup = 'RED' | 'ORANGE' | 'GOLD' | 'MUSTARD' | 'YELLOW' | 'LIME' | 'GREEN' | 'TEAL' | 'AQUA' 
                             | 'SKY' | 'BLUE' | 'PURPLE' | 'MAGENTA' | 'PINK' | 'ROSE' | 'BROWN' | 'GRAY';
    export type Shade = 'DARK_4' | 'DARK_3' | 'DARK_2' | 'DARK' | 'NEUTRAL' | 'LIGHT' | 'LIGHT_2' | 'LIGHT_3';

    export interface Color {
        hex: `#${string}`,
        group: ColorGroup,
        shade: Shade,
        idOverride?: string
    };

    function groupToColumn(group: ColorGroup): number {
        return [{}, 'RED', 'ORANGE', 'GOLD', 'MUSTARD', 'YELLOW', 'LIME', 'GREEN', 'TEAL', 'AQUA', 'SKY', 'BLUE', 'PURPLE', 'MAGENTA', 'PINK', 'ROSE', 'BROWN', 'GRAY']
            .indexOf(group);
    }

    function shadeToRow(shade: Shade): number {
        return [{}, 'DARK_4', 'DARK_3', 'DARK_2', 'DARK', 'NEUTRAL', 'LIGHT', 'LIGHT_2', 'LIGHT_3']
            .indexOf(shade);
    }
</script>

<script lang="ts">
    import { createEventDispatcher } from 'svelte';

    export let color: Readonly<Color>

    const dispatch = createEventDispatcher<{ 'click': SwatchSelectEvent }>();

    function handleClick() {
        dispatch('click', { color });
    }
</script>

<div class="swatch" style={`--color: ${color.hex}; --row: ${shadeToRow(color.shade)}; --column: ${groupToColumn(color.group)}`} on:click={handleClick} />

<style>
    .swatch {
        width: 3vmax;
        height: 3vmax;

        transition-property: transform, box-shadow, border-radius;
        transition-duration: 0.1s;

        background-color: var(--color);

        grid-row: var(--row);
        grid-column: var(--column);
    }

    /* Invert rows and columns on smaller screen sizes */
    @media only screen and (max-device-width: 667px) {
        .swatch {
            grid-column: var(--row);
            grid-row: var(--column);
        }
    }

    div:hover {
        z-index: 1;
        transform: scale(115%);
        border-radius: 3px;
        box-shadow: 0 0 20px 20px #00000011;
    }
</style>