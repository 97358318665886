<script lang="ts">
    import Swatch from './Swatch.svelte';
</script>

<section>
    <div id="grid">
        <!-- red -->
        <Swatch on:click color={ { hex: '#550000', group: 'RED', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#AA0000', group: 'RED', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#FF0000', group: 'RED', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#FF5555', group: 'RED', shade: 'LIGHT' } } />
        <Swatch on:click color={ { hex: '#FFAAAA', group: 'RED', shade: 'LIGHT_2' } } />
        <Swatch on:click color={ { hex: '#FFD4D4', group: 'RED', shade: 'LIGHT_3' } } />

        <!-- orange -->
        <Swatch on:click color={ { hex: '#D42A00', group: 'ORANGE', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#FF5500', group: 'ORANGE', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#FF7F55', group: 'ORANGE', shade: 'LIGHT' } } />
        <Swatch on:click color={ { hex: '#FFAA7F', group: 'ORANGE', shade: 'LIGHT_2' } } />

        <!-- gold -->
        <Swatch on:click color={ { hex: '#7F2A00', group: 'GOLD', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#AA5500', group: 'GOLD', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#FFAA00', group: 'GOLD', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#FFD47F', group: 'GOLD', shade: 'LIGHT' } } />

        <!-- mustard -->
        <Swatch on:click color={ { hex: '#AA7F00', group: 'MUSTARD', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#D4AA2A', group: 'MUSTARD', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#FFD42A', group: 'MUSTARD', shade: 'NEUTRAL' } } />

        <!-- yellow -->
        <Swatch on:click color={ { hex: '#7F7F2A', group: 'YELLOW', shade: 'DARK_3' } } />
        <Swatch on:click color={ { hex: '#AAAA55', group: 'YELLOW', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#D4D455', group: 'YELLOW', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#FFFF55', group: 'YELLOW', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#FFFFAA', group: 'YELLOW', shade: 'LIGHT' } } />
        <Swatch on:click color={ { hex: '#FFFFD4', group: 'YELLOW', shade: 'LIGHT_2' } } />

        <!-- lime -->
        <Swatch on:click color={ { hex: '#55AA00', group: 'LIME', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#7FD42A', group: 'LIME', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#AAFF55', group: 'LIME', shade: 'NEUTRAL' } } />

        <!-- green -->
        <Swatch on:click color={ { hex: '#005500', group: 'GREEN', shade: 'DARK_4' } } />
        <Swatch on:click color={ { hex: '#2A7F00', group: 'GREEN', shade: 'DARK_3' } } />
        <Swatch on:click color={ { hex: '#00AA00', group: 'GREEN', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#2AD42A', group: 'GREEN', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#55FF55', group: 'GREEN', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#7FFF7F', group: 'GREEN', shade: 'LIGHT' } } />
        <Swatch on:click color={ { hex: '#AAFFAA', group: 'GREEN', shade: 'LIGHT_2' } } />
        <Swatch on:click color={ { hex: '#D4FFD4', group: 'GREEN', shade: 'LIGHT_3' } } />

        <!-- teal -->
        <Swatch on:click color={ { hex: '#00D4AA', group: 'TEAL', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#2AFFAA', group: 'TEAL', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#7FFFD4', group: 'TEAL', shade: 'LIGHT' } } />

        <!-- aqua -->
        <Swatch on:click color={ { hex: '#005555', group: 'AQUA', shade: 'DARK_3' } } />
        <Swatch on:click color={ { hex: '#007F7F', group: 'AQUA', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#00AAAA', group: 'AQUA', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#2AD4D4', group: 'AQUA', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#55FFFF', group: 'AQUA', shade: 'LIGHT' } } />
        <Swatch on:click color={ { hex: '#AAFFFF', group: 'AQUA', shade: 'LIGHT_2' } } />
        <Swatch on:click color={ { hex: '#D4FFFF', group: 'AQUA', shade: 'LIGHT_3' } } />

        <!-- sky -->
        <Swatch on:click color={ { hex: '#2A70D4', group: 'SKY', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#55AAFF', group: 'SKY', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#AAD4FF', group: 'SKY', shade: 'LIGHT' } } />

        <!-- blue -->
        <Swatch on:click color={ { hex: '#000055', group: 'BLUE', shade: 'DARK_3' } } />
        <Swatch on:click color={ { hex: '#0000AA', group: 'BLUE', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#5500FF', group: 'BLUE', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#5555FF', group: 'BLUE', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#7070FF', group: 'BLUE', shade: 'LIGHT' } } />
        <Swatch on:click color={ { hex: '#AAAAFF', group: 'BLUE', shade: 'LIGHT_2' } } />
        <Swatch on:click color={ { hex: '#D4D4FF', group: 'BLUE', shade: 'LIGHT_3' } } />

        <!-- purple -->
        <Swatch on:click color={ { hex: '#2A007F', group: 'PURPLE', shade: 'DARK_3' } } />
        <Swatch on:click color={ { hex: '#5500AA', group: 'PURPLE', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#7F2AD4', group: 'PURPLE', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#AA55FF', group: 'PURPLE', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#AA7FFF', group: 'PURPLE', shade: 'LIGHT' } } />
        <Swatch on:click color={ { hex: '#D4AAFF', group: 'PURPLE', shade: 'LIGHT_2' } } />

        <!-- magenta -->
        <Swatch on:click color={ { hex: '#550055', group: 'MAGENTA', shade: 'DARK_3' } } />
        <Swatch on:click color={ { hex: '#7F007F', group: 'MAGENTA', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#AA00AA', group: 'MAGENTA', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#D42AD4', group: 'MAGENTA', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#FF55FF', group: 'MAGENTA', shade: 'LIGHT' } } />
        <Swatch on:click color={ { hex: '#FFAAFF', group: 'MAGENTA', shade: 'LIGHT_2' } } />
        <Swatch on:click color={ { hex: '#FFD4FF', group: 'MAGENTA', shade: 'LIGHT_3' } } />

        <!-- pink -->
        <Swatch on:click color={ { hex: '#D5007F', group: 'PINK', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#FF00AA', group: 'PINK', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#FF55AA', group: 'PINK', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#FF7FD4', group: 'PINK', shade: 'LIGHT' } } />

        <!-- rose -->
        <Swatch on:click color={ { hex: '#FF0055', group: 'ROSE', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#FF557F', group: 'ROSE', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#FF7FAA', group: 'ROSE', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#FFAAD4', group: 'ROSE', shade: 'LIGHT' } } />

        <!-- brown -->
        <Swatch on:click color={ { hex: '#552A00', group: 'BROWN', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#80552A', group: 'BROWN', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#AA8055', group: 'BROWN', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#D4AA80', group: 'BROWN', shade: 'LIGHT' } } />
        <Swatch on:click color={ { hex: '#FFD4AA', group: 'BROWN', shade: 'LIGHT_2' } } />

        <!-- gray -->
        <Swatch on:click color={ { hex: '#000000', group: 'GRAY', shade: 'DARK_3', idOverride: 'BLACK' } } />
        <Swatch on:click color={ { hex: '#2A2A2A', group: 'GRAY', shade: 'DARK_2' } } />
        <Swatch on:click color={ { hex: '#555555', group: 'GRAY', shade: 'DARK' } } />
        <Swatch on:click color={ { hex: '#808080', group: 'GRAY', shade: 'NEUTRAL' } } />
        <Swatch on:click color={ { hex: '#AAAAAA', group: 'GRAY', shade: 'LIGHT' } } />
        <Swatch on:click color={ { hex: '#D4D4D4', group: 'GRAY', shade: 'LIGHT_2' } } />
        <Swatch on:click color={ { hex: '#FFFFFF', group: 'GRAY', shade: 'LIGHT_3', idOverride: 'WHITE' } } />
    </div>
</section>

<style>
    section {
        margin: auto;
        flex-basis: 70%;
    }

    section > #grid {
        margin: auto;
        display: grid;
        width: auto;
    }
</style>
